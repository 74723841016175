import React, { useEffect, useState } from "react";
import { Grid, Stepper, Typography, Step, StepLabel, styled, StepIconProps } from "@mui/material";
import HotelTabContent from "./HotelTab/index";

import { CONFIRMATION, EXTRA_COSTS, HOTEL, PASSENGERS } from "./const";
import PassengersTabContent from "./PassengersTab";
import ExtraCostsTabContent from "./ExtraCostsTab";
import ConfirmationTabContent from "./ConfirmationTab";
import { useParams } from "react-router-dom";
import Post from "lib/api/post";
import { useFormContext } from "./FormContextProvide";

const steps = [HOTEL, PASSENGERS, EXTRA_COSTS, CONFIRMATION];

const ReservationContent = () => {
  const { id } = useParams();

  const { formContext, setFormContext } = useFormContext();

  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = useState(new Set<number>());

  const isStepOptional = (step: number) => {
    return step === 1;
  };

  const isStepSkipped = (step: number) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSubmit = () => {
    setActiveStep(0);
  };

  const ColorlibStepIconRoot = styled("div")<{
    ownerState: { completed?: boolean; active?: boolean };
  }>(({ theme, ownerState }) => ({
    backgroundColor: "transparent",
    zIndex: 1,
    color: "#626ed4",
    border: "1px solid",
    borderColor: "#626ed4",
    width: 38,
    height: 38,
    display: "flex",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center",
    ...(ownerState.active && {
      color: "#fff",
      borderColor: "#fff",
    }),
    ...(ownerState.completed && {
      color: "#626ed4",
      borderColor: "#626ed4",
    }),
  }));

  const ColorlibStepIcon = (props: StepIconProps) => {
    const { active, completed, className } = props;

    const icons: { [index: string]: React.ReactElement } = {
      1: <span>1.</span>,
      2: <span>2.</span>,
      3: <span>3.</span>,
      4: <span>4.</span>,
    };

    return (
      <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
        {icons[String(props.icon)]}
      </ColorlibStepIconRoot>
    );
  };

  const renderSteps = (stepNumber: number) => {
    switch (stepNumber) {
      case 0:
      default:
        return <HotelTabContent activeStep={activeStep} next={handleNext} back={handleBack} />;
      case 1:
        return <PassengersTabContent activeStep={activeStep} next={handleNext} back={handleBack} />;

      case 2:
        return <ExtraCostsTabContent activeStep={activeStep} next={handleNext} back={handleBack} />;

      case 3:
        return (
          <ConfirmationTabContent
            activeStep={activeStep}
            next={handleNext}
            back={handleBack}
            formSubmit={handleSubmit}
          />
        );
    }
  };
  const [data, setdata] = useState<any>(null);

  const loadData = async () => {
    const user = JSON.parse(localStorage.getItem("user"));
    await Post.loadReservation({
      reservation_id: id,
      session_id: user?.session_id,
      user_id: user?.uid,
    })
      .then((res) => setdata(JSON.parse(res.data.reservation)))
      .catch((error) => console.log("error", error));
  };

  useEffect(() => {
    if (id) {
      loadData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);
  useEffect(() => {
    if (id) {
      setFormContext({
        hotel_note: data?.hotel_note ? data?.hotel_note : "",
        operation_note: data?.operation_note ? data?.operation_note : "",
        status: data?.status ? data?.status : 1,
        rooms: data?.rooms ? data?.rooms : [],
        extras: data?.extras ? data?.extras : [],
        hotels: data?.hotels ? data?.hotels : [],
        voucher: data?.voucher ? data?.voucher : "",
        agancy_company: data?.agancy_company ? data?.agancy_company : "",
        agancy_id: data?.agancy_id ? data?.agancy_id : null,
        agancy_name: data?.agancy_name ? data?.agancy_name : "",
        agency_note: data?.agency_note ? data?.agency_note : "",
        resseller_id: data?.reseller_id ? data?.reseller_id : null,
        resseller_name: data?.reseller_name ? data?.reseller_name : "",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, data]);
  return (
    <Grid bgcolor={"#fff"} borderRadius="10px" p={2} mb={2}>
      <Stepper
        activeStep={activeStep}
        sx={{ background: "transparent", boxShadow: "none" }}
        connector={<></>}
      >
        {steps.map((label, index) => {
          const stepProps: { completed?: boolean } = {};
          const labelProps: {
            optional?: React.ReactNode;
          } = {};
          if (isStepOptional(index)) {
            labelProps.optional = <Typography variant="caption">Optional</Typography>;
          }
          if (isStepSkipped(index)) {
            stepProps.completed = false;
          }
          return (
            <Step
              key={label}
              {...stepProps}
              sx={{
                width: "25%",
                px: 2,
                "&> .MuiStepLabel-root": {
                  fontWeight: "500",
                  borderRadius: "5px",
                  px: 2,
                  py: 1,
                  bgcolor: "#626ed4",
                  "& .MuiStepLabel-label": {
                    fontWeight: "500",
                  },
                },
                "&> .MuiStepLabel-root.Mui-disabled": {
                  bgcolor: "#daddf5",
                  "& .MuiStepLabel-label": {
                    fontWeight: "500",
                    color: "#626ed4",
                  },
                },
                "&.Mui-completed>.MuiStepLabel-root": {
                  bgcolor: "#daddf5",
                  "& .MuiStepLabel-label": {
                    fontWeight: "500",
                    color: "#626ed4 !important",
                  },
                },
              }}
            >
              <StepLabel StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      {renderSteps(activeStep)}
    </Grid>
  );
};

export default ReservationContent;
