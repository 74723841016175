import {
  Button,
  Card,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  Select,
  Stack,
  TextField,
} from "@mui/material";
import { BUY_PRICE, CURRENCY, HOTEL, SELL_PRICE, TITLE, TYPE } from "./const";
import { Switch } from "@material-ui/core";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { useFormik } from "formik";
import { useRequirmentContext } from "../../RequirmentContextProvider";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
interface ICostFormProps {
  id: string;
  handleDelete: any;
  order?: number;
  handleChangeValue?: any;
  row?: any;
  formContext?: any;
  setFormContext?: any;
}
const CostForm = ({
  id,
  handleDelete,
  order,
  handleChangeValue,
  row,
  setFormContext,
  formContext,
}: ICostFormProps) => {
  const params = useParams();

  const { requirmentContext } = useRequirmentContext();
  const { handleChange, handleBlur, values, setFieldValue } = useFormik<any>({
    initialValues: {
      buyPriceCurrency: row?.buyPriceCurrency
        ? `{"id":${row?.buyPriceCurrency ? row?.buyPriceCurrency : 1},"code":"${
            row?.buyPriceCurrencyCode ? row?.buyPriceCurrencyCode : "USD"
          }"}`
        : 1,
      // row?.buyPriceCurrency ? row?.buyPriceCurrency : 1,
      buyPriceCurrencyCode: row?.buyPriceCurrencyCode ? row?.buyPriceCurrencyCode : "USD",
      sellPriceCurrency: row?.sellPriceCurrency
        ? `{"id":${row?.sellPriceCurrency ? row?.sellPriceCurrency : 1},"code":"${
            row?.sellPriceCurrencyCode ? row?.sellPriceCurrencyCode : "USD"
          }"}`
        : 1,
      // row?.sellPriceCurrency ? row?.sellPriceCurrency : 1,
      sellPriceCurrencyCode: row?.sellPriceCurrencyCode ? row?.sellPriceCurrencyCode : "USD",
      extra_id: row?.extraType ? row?.extraType : row?.extra_id ? row?.extra_id : 0,
      extraType: row?.extraType ? row?.extraType : 0,
      id: row?.id ? row?.id : id,
      order: row?.order ? row?.order : order,
      hotel_based: row?.hotel_based ? row?.hotel_based : 0,
      buyPrice: row?.buyPrice ? row?.buyPrice : 0,
      sellPrice: row?.sellPrice ? row?.sellPrice : 0,
      title: row?.title ? row?.title : "",
    },
    onSubmit: (values) => {},
    enableReinitialize: true,
  });
  useEffect(() => {
    const valueSellPriceCurrency = JSON.parse(values?.sellPriceCurrency);
    const valueBuyPriceCurrency = JSON.parse(values?.buyPriceCurrency);
    const formikValues = {
      ...values,
      buyPriceCurrency: valueBuyPriceCurrency?.id
        ? valueBuyPriceCurrency?.id
        : values?.buyPriceCurrency,
      buyPriceCurrencyCode: valueBuyPriceCurrency?.code
        ? valueBuyPriceCurrency?.code
        : values?.buyPriceCurrencyCode,
      sellPriceCurrency: valueSellPriceCurrency?.id
        ? valueSellPriceCurrency?.id
        : values?.sellPriceCurrency,
      sellPriceCurrencyCode: valueSellPriceCurrency?.code
        ? valueSellPriceCurrency?.code
        : values?.sellPriceCurrencyCode,
      extraType: values?.extra_id,
    };
    if (!params?.id) {
      handleChangeValue?.({
        ...formikValues,
      });
    }
  }, [values]);
  return (
    <Card variant="outlined" sx={{ p: 2, mb: 1 }}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Button
            variant="outlined"
            size="small"
            color="secondary"
            onClick={() => handleDelete(id)}
          >
            <DeleteOutlineIcon />
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Stack direction="column" spacing={1.25}>
            <FormControl fullWidth required>
              <TextField
                size="small"
                label={TITLE}
                required
                name="title"
                value={values.title}
                onChange={(e) => {
                  handleChange(e);
                }}
                onBlur={handleBlur}
              />
            </FormControl>
            <FormControl fullWidth required>
              <InputLabel id="extra_id">{TYPE}</InputLabel>
              <Select
                native
                label={TYPE}
                labelId="extra_id"
                fullWidth
                name="extra_id"
                required
                value={values.extra_id}
                onBlur={handleBlur}
                onChange={(e: any) => {
                  handleChange(e);
                }}
              >
                <option value=""></option>
                {requirmentContext?.cost_types?.map((item: any) => (
                  <option key={item.id} value={item.id}>
                    {item.title}
                  </option>
                ))}
              </Select>
            </FormControl>
          </Stack>
        </Grid>
        <Grid item xs={6} sm={3}>
          <Stack direction="column" spacing={1.25}>
            <FormControl fullWidth required sx={{ mb: 1 }}>
              <TextField
                label={BUY_PRICE}
                required
                type="number"
                variant="outlined"
                name="buyPrice"
                value={values.buyPrice}
                onBlur={handleBlur}
                onChange={(e: any) => {
                  handleChange(e);
                }}
              />
            </FormControl>
            <FormControl fullWidth required>
              <InputLabel id="buyPriceCurrency">{CURRENCY}</InputLabel>
              <Select
                native
                required
                fullWidth
                label={CURRENCY}
                labelId="buyPriceCurrency"
                name="buyPriceCurrency"
                value={values.buyPriceCurrency}
                onBlur={handleBlur}
                onChange={(e: any) => {
                  const value = JSON.parse(e.target.value);
                  handleChange(e);
                }}
              >
                {requirmentContext?.currencies?.map((item: any) => (
                  <option
                    key={item.id}
                    value={JSON.stringify({
                      id: item.id,
                      code: item.code,
                    })}
                  >
                    {item.code}
                  </option>
                ))}
              </Select>
            </FormControl>
            <FormControlLabel
              control={
                <Switch
                  checked={values.hotel_based}
                  value={values.hotel_based}
                  onChange={(e) => {
                    setFieldValue("hotel_based", e?.target?.checked ? 1 : 0);
                  }}
                  onBlur={handleBlur}
                  name="hotel_based"
                  color="primary"
                />
              }
              label={HOTEL}
            />
          </Stack>
        </Grid>

        <Grid item xs={6} sm={3}>
          <Stack direction="column" spacing={1.25}>
            <FormControl fullWidth required>
              <TextField
                required
                label={SELL_PRICE}
                type="number"
                variant="outlined"
                name="sellPrice"
                value={values.sellPrice}
                onBlur={handleBlur}
                onChange={(e: any) => {
                  handleChange(e);
                }}
              />
            </FormControl>
            <FormControl fullWidth required>
              <InputLabel id="sellPriceCurrency">{CURRENCY}</InputLabel>
              <Select
                native
                required
                fullWidth
                label={CURRENCY}
                labelId="sellPriceCurrency"
                name="sellPriceCurrency"
                value={values.sellPriceCurrency}
                onChange={(e: any) => {
                  const value = JSON.parse(e.target.value);
                  handleChange(e);
                }}
                onBlur={handleBlur}
              >
                {requirmentContext?.currencies?.map((item: any) => (
                  <option
                    key={item.id}
                    value={JSON.stringify({
                      id: item.id,
                      code: item.code,
                    })}
                  >
                    {item.code}
                  </option>
                ))}
              </Select>
            </FormControl>
          </Stack>
        </Grid>
      </Grid>
    </Card>
  );
};

export default CostForm;
