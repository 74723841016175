import {
  Box,
  Card,
  Divider,
  Grid,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { AGES, NIGHTS, TOTALS, TOTAL } from "./const";
import { HOTEL } from "../../const";
import { diffDaysOfTwoDate } from "utils/helpers";
import { useEffect, useState } from "react";
interface IHotelItemProps {
  room: any;
  index: number;
  formContext: any;
}
const HotelItem = ({ room, index, formContext }: IHotelItemProps) => {
  const [val, setVal] = useState<any>([]);
  useEffect(() => {
    let keepPrice = 0;
    let keepType = "";
    let arrayData: any = [];
    if (room?.financial && room?.financial?.length > 0) {
      room?.financial
        ?.sort((a: any, b: any) => {
          if (a.sellPriceCurrencyCode < b.sellPriceCurrencyCode) {
            return -1;
          }
          if (a.sellPriceCurrencyCode > b.sellPriceCurrencyCode) {
            return 1;
          }
          return 0;
        })
        .map((price: any, index: any) => {
          if (keepPrice === 0) {
            keepPrice += price?.sellPrice * price?.nights;
            keepType = price?.sellPriceCurrencyCode;
            if (index + 1 === room?.financial?.length) {
              arrayData.push(`${keepPrice} ${keepType}`);
            }
          } else {
            if (
              price?.sellPriceCurrencyCode === room?.financial[index - 1]?.sellPriceCurrencyCode
            ) {
              keepPrice += price?.sellPrice * price?.nights;
              keepType = price?.sellPriceCurrencyCode;
              if (index + 1 === room?.financial?.length) {
                arrayData.push(`${keepPrice} ${keepType}`);
              }
            } else {
              arrayData.push(`${keepPrice} ${keepType}`);
              keepPrice = 0;
              keepPrice += price?.sellPrice * price?.nights;
              keepType = price?.sellPriceCurrencyCode;
              if (index + 1 === room?.financial?.length) {
                arrayData.push(`${keepPrice} ${keepType}`);
              }
            }
          }
        });
      setVal(arrayData);
    } else {
      room?.financials
        ?.sort((a: any, b: any) => {
          if (a.sellPriceCurrencyCode < b.sellPriceCurrencyCode) {
            return -1;
          }
          if (a.sellPriceCurrencyCode > b.sellPriceCurrencyCode) {
            return 1;
          }
          return 0;
        })
        .map((price: any, index: any) => {
          if (keepPrice === 0) {
            keepPrice = price?.sell_price * price?.nights + keepPrice;
            keepType = price?.sellPriceCurrencyCode;
            if (index + 1 === room?.financials?.length) {
              arrayData.push(`${keepPrice} ${keepType}`);
            }
          } else {
            if (
              price?.sellPriceCurrencyCode === room?.financials[index - 1]?.sellPriceCurrencyCode
            ) {
              keepPrice += price?.sell_price * price?.nights;
              keepType = price?.sellPriceCurrencyCode;
              if (index + 1 === room?.financials?.length) {
                arrayData.push(`${keepPrice} ${keepType}`);
              }
            } else {
              arrayData.push(`${keepPrice} ${keepType}`);
              keepPrice = 0;
              keepPrice += price?.sell_price * price?.nights;
              keepType = price?.sellPriceCurrencyCode;
              if (index + 1 === room?.financials?.length) {
                arrayData.push(`${keepPrice} ${keepType}`);
              }
            }
          }
        });
      setVal(arrayData);
    }
  }, [room]);

  return (
    <Card variant="outlined" sx={{ p: 1, mb: 1 }}>
      <Grid container spacing={3} sx={{ fontSize: "14px" }}>
        <Grid item xs={12}>
          {index === 0 && (
            <Stack direction="column" spacing={1.25} alignItems="flex-end">
              #{formContext.voucher}
            </Stack>
          )}
        </Grid>
        <Grid item style={{ paddingTop: "10px" }} xs={12}>
          <Divider />
        </Grid>
        <Grid style={{ paddingTop: "10px" }} item xs={12}>
          <Stack direction="row" justifyContent="space-between">
            <Stack direction="column" spacing={1.25} alignItems="flex-start">
              <Typography variant="subtitle2" sx={{ fontSize: "1em", fontWeight: "300" }}>
                {formContext?.agency_name}
              </Typography>
              <Typography variant="subtitle2" sx={{ fontSize: "1em", fontWeight: "300" }}>
                {formContext?.resseller_name}
              </Typography>
            </Stack>
            <Stack direction="column" spacing={1.25} alignItems="flex-end">
              {index === 0 ? (
                <>
                  <Typography variant="subtitle1" sx={{ fontSize: "1.2em", fontWeight: "bold" }}>
                    {HOTEL}
                  </Typography>
                  <Typography variant="subtitle2" sx={{ fontSize: "1em", fontWeight: "300" }}>
                    {room?.hotel_title}
                  </Typography>
                  <Typography variant="subtitle2" sx={{ fontSize: "1em", fontWeight: "300" }}>
                    {room?.state_name}, {room?.location}
                  </Typography>
                  {/* {updateData(room)} */}
                </>
              ) : (
                formContext?.rooms[index - 1].hotel_id !== room.hotel_id && (
                  <>
                    <Typography variant="subtitle1" sx={{ fontSize: "1.2em", fontWeight: "bold" }}>
                      {HOTEL}
                    </Typography>
                    <Typography variant="subtitle2" sx={{ fontSize: "1em", fontWeight: "300" }}>
                      {room?.hotel_title}
                    </Typography>
                    <Typography variant="subtitle2" sx={{ fontSize: "1em", fontWeight: "300" }}>
                      {room?.state_name}, {room?.location}
                    </Typography>
                    {/* {updateData(room)} */}
                  </>
                )
              )}
            </Stack>
          </Stack>
        </Grid>
        <Grid style={{ paddingTop: "10px" }} item xs={12}>
          <Stack direction="row" spacing={1.25} alignItems="flex-start">
            <Typography variant="subtitle2" sx={{ fontSize: "1em", fontWeight: "300" }}>
              {`${room?.room_type1_title} > ${room?.room_type2_title}`}{" "}
              {room?.pansiyonCode && `(${room?.pansiyonCode})`}
            </Typography>
          </Stack>
        </Grid>
        <Grid style={{ paddingTop: "10px" }} item xs={12}>
          <Stack direction="column" spacing={1.25} alignItems="flex-start">
            <Typography variant="subtitle2" sx={{ fontSize: "1em", fontWeight: "300" }}>
              {room.checkIn && room.checkOut ? `${room.checkIn} to ${room.checkOut}` : ""}
            </Typography>
            <Typography variant="subtitle2" sx={{ fontSize: "1em", fontWeight: "bold" }}>
              {`${
                room?.checkIn &&
                room?.checkOut &&
                diffDaysOfTwoDate(new Date(room?.checkIn), new Date(room?.checkOut))
              } ${NIGHTS}` !== "undefined Night(s)"
                ? `${
                    room?.checkIn &&
                    room?.checkOut &&
                    diffDaysOfTwoDate(new Date(room?.checkIn), new Date(room?.checkOut))
                  } ${NIGHTS}`
                : ""}
            </Typography>
          </Stack>
        </Grid>
        <Grid style={{ paddingTop: "10px" }} item xs={12}>
          <Stack direction="row" sx={{ px: 1 }}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead sx={{ display: "contents" }}>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell align="center">{AGES}</TableCell>
                  <TableCell align="center">{TOTALS}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {room?.passengers?.map((passenger: any) => (
                  <TableRow
                    key={passenger.id}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {passenger.label ? passenger.label : passenger.fullname}
                    </TableCell>
                    <TableCell align="center"> {passenger.age_type} </TableCell>
                    <TableCell align="center">-</TableCell>
                  </TableRow>
                ))}
                <TableRow>
                  <TableCell colSpan={1} />
                  <TableCell align="center">
                    <Typography variant="subtitle1" sx={{ fontSize: "1.2em", fontWeight: "bold" }}>
                      {TOTAL}
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    {room.financials
                      ? room.financials?.length > 0 &&
                        val.map((item: any) => {
                          return (
                            <Typography
                              variant="subtitle2"
                              sx={{ fontSize: "1em", fontWeight: "300" }}
                            >
                              {`${item}`}
                            </Typography>
                          );
                        })
                      : null}
                    {room.financial &&
                      room.financial?.length > 0 &&
                      val.map((item: any) => {
                        return (
                          <Typography
                            variant="subtitle2"
                            sx={{ fontSize: "1em", fontWeight: "300" }}
                          >
                            {`${item}`}
                          </Typography>
                        );
                      })}
                  </TableCell>
                </TableRow>
                {room?.extras && room?.extras?.length !== 0 && (
                  <TableRow>
                    <TableCell colSpan={12}>
                      {room?.extras.map((extra: any) => {
                        return (
                          <Stack direction="row" justifyContent="space-evenly">
                            <Box />
                            <Box>
                              <i>(Arac Vip)</i> {extra?.title}
                            </Box>
                            <Box>
                              {`${Number(extra.sellPrice).toLocaleString()} ${
                                extra?.sellPriceCurrencyCode
                              }`}
                            </Box>
                          </Stack>
                        );
                      })}
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </Stack>
        </Grid>
      </Grid>
      <Box>
        <Box>
          <TableContainer component={Paper}></TableContainer>
        </Box>
      </Box>
    </Card>
  );
};

export default HotelItem;
