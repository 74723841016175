import React, {
  useState,
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
} from "react";
import { useParams } from "react-router-dom";
// import { data } from "./test";
import Post from "lib/api/post";

type FormContextProps = any;

type ContextValue = {
  formContext: FormContextProps;
  setFormContext: Dispatch<SetStateAction<FormContextProps>>;
};

const FormContext = createContext<ContextValue | undefined>(undefined);

const FormContextProvider: React.FC = ({ children }) => {
  const { id } = useParams();
  const [data, setdata] = useState<any>(null);
  const loadData = async () => {
    const user = JSON.parse(localStorage.getItem("user"));
    await Post.loadReservation({
      reservation_id: id,
      session_id: user?.session_id,
      user_id: user?.uid,
    })
      .then((res) => setdata(JSON.parse(res.data.reservation)))
      .catch((error) => console.log("error", error));
  };
  useEffect(() => {
    if (id) {
      loadData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);
  const [hotelReservation, setHotelReservation] = useState<any>(id ? data : null);
  const [formContext, setFormContext] = useState<FormContextProps>({
    hotel_note: data?.hotel_note ? data?.hotel_note : "",
    operation_note: data?.operation_note ? data?.operation_note : "",
    status: data?.status ? data?.status : 1,
    rooms: data?.rooms ? data?.rooms : [],
    extras: data?.extras ? data?.extras : [],
    hotels: data?.hotels ? data?.hotels : [],
    voucher: data?.voucher ? data?.voucher : "",
    agancy_company: data?.agancy_company ? data?.agancy_company : "",
    agancy_id: data?.agancy_id ? data?.agancy_id : null,
    agancy_name: data?.agancy_name ? data?.agancy_name : "",
    agency_note: data?.agency_note ? data?.agency_note : "",
    resseller_id: data?.reseller_id ? data?.reseller_id : null,
    resseller_name: data?.reseller_name ? data?.reseller_name : "",
  });
  useEffect(() => {
    if (id) {
      setFormContext({
        hotel_note: data?.hotel_note ? data?.hotel_note : "",
        operation_note: data?.operation_note ? data?.operation_note : "",
        status: data?.status ? data?.status : 1,
        rooms: data?.rooms ? data?.rooms : [],
        extras: data?.extras ? data?.extras : [],
        hotels: data?.hotels ? data?.hotels : [],
        voucher: data?.voucher ? data?.voucher : "",

        agancy_company: data?.agancy_company ? data?.agancy_company : "",
        agancy_id: data?.agancy_id ? data?.agancy_id : null,
        agancy_name: data?.agancy_name ? data?.agancy_name : "",
        agency_note: data?.agency_note ? data?.agency_note : "",
        resseller_id: data?.reseller_id ? data?.reseller_id : null,
        resseller_name: data?.reseller_name ? data?.reseller_name : "",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, data]);
  return (
    <FormContext.Provider value={{ formContext, setFormContext }}>{children}</FormContext.Provider>
  );
};

export default FormContextProvider;

export const useFormContext = () => {
  const context = useContext(FormContext);
  if (!context) {
    throw new Error("useFormContext must be used inside the FormContextProvider");
  }
  return context;
};
