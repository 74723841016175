import {
  Button,
  Collapse,
  Grid,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import HotelItem from "./components/HotelItem";
import TotalPrices from "./components/TotalPrices";
import { useFormContext } from "../FormContextProvide";
import PrintIcon from "@mui/icons-material/Print";
import Post from "lib/api/post";
import { PREVIOUS, SUBMIT, MODIFY } from "../const";
import { useNavigate, useParams } from "react-router-dom";
import Put from "lib/api/put";
import { INVOICE, REQUEST, VOUCHER } from "../ExtraCostsTab/components/const";
import { useState } from "react";
import { SELECT_USER } from "store/slices/profile";
import { BASE_URL } from "lib/constants/urls";

type Props = {
  next: () => void;
  back: () => void;
  activeStep: number;
  formSubmit: () => void;
};

const ConfirmationTabContent = ({ next, back, activeStep, formSubmit }: Props) => {
  const { formContext, setFormContext } = useFormContext();
  const [btnType, setBtnType] = useState({ status: false, type: null });
  const { id } = useParams();
  const user = SELECT_USER();
  const navigate = useNavigate();
  const handleSubmit = async () => {
    const user = JSON.parse(localStorage.getItem("user"));

    const form = {
      ...formContext,
      session_id: user?.session_id,
      user_id: user?.uid,
    };
    if (!id) {
      await Post.addReservation(form)
        .then((res) => {
          console.log("res", res);
          formSubmit();
          setFormContext({
            hotel_note: "",
            operation_note: "",
            status: 1,
            rooms: [],
            extras: [],
          });
        })
        .catch((error) => {
          console.log("error", error);
        });
    } else {
      const newRooms = form?.rooms?.map((item: any) => {
        const { financials, ...rest } = item;
        return {
          ...rest,
          financial: financials || item?.financial,
        };
      });
      await Put.updateReservation({ ...form, reservation_id: id, rooms: newRooms })
        .then((res) => {
          formSubmit();
          setFormContext({
            hotel_note: "",
            operation_note: "",
            status: 1,
            rooms: [],
            extras: [],
          });
          navigate("/reservation/archive");
        })
        .catch((error) => {
          console.log("error", error);
        });
    }
  };

  return (
    <Grid container>
      {formContext?.rooms
        ?.sort((a: any, b: any) => a.hotel_title - b.hotel_title)
        .map((room: any, index: number) => {
          return (
            <Grid key={index + 1} xs={12} item>
              <HotelItem room={room} index={index} formContext={formContext} />
            </Grid>
          );
        })}
      <Grid xs={12} item>
        <TotalPrices />
      </Grid>
      <Grid xs={12} item direction="row" justifyContent="flex-end" spacing={2} sx={{ mt: 2 }}>
        <Button
          variant="contained"
          size="medium"
          style={{ marginLeft: "4px", marginRight: "4px" }}
          color="secondary"
        >
          <PrintIcon />
        </Button>
        {id && (
          <>
            <Button
              variant="contained"
              size="medium"
              style={{ marginLeft: "4px", marginRight: "4px" }}
              color="primary"
              onClick={() => {
                setBtnType({ status: true, type: "invoice" });
              }}
            >
              <PrintIcon /> {INVOICE}
            </Button>
            <Button
              variant="contained"
              size="medium"
              style={{ marginLeft: "4px", marginRight: "4px" }}
              color="primary"
              onClick={() => {
                setBtnType({ status: true, type: "request" });
              }}
            >
              <PrintIcon /> {REQUEST}
            </Button>
            <Button
              variant="contained"
              size="medium"
              style={{ marginLeft: "4px", marginRight: "4px" }}
              color="primary"
              onClick={() => {
                setBtnType({ status: true, type: "voucher" });
              }}
            >
              <PrintIcon /> {VOUCHER}
            </Button>
          </>
        )}
      </Grid>
      {id && (
        <Grid xs={12} item direction="row" justifyContent="flex-end" spacing={2} sx={{ mt: 2 }}>
          <Collapse in={btnType?.status} timeout="auto" unmountOnExit>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Hotel</TableCell>
                    <TableCell>Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {formContext?.hotels?.map((hotel: any) => {
                    return (
                      <TableRow>
                        <TableCell>{hotel?.hotel_name || hotel?.fullname}</TableCell>
                        <TableCell>
                          {btnType?.type === "invoice" ? (
                            <Button
                              variant="contained"
                              size="medium"
                              style={{ marginLeft: "4px", marginRight: "4px" }}
                              color="primary"
                              // onClick={() => {
                              //   setBtnType({ status: true, type: "invoice" });
                              // }}
                            >
                              <a
                                href={`${BASE_URL}/reservations/getRequestPdf?sid=${user?.session_id}&tk=${user?.accessToken}&reservation_id=${id}&type=invoice&hotel_id=${hotel?.id}`}
                                target="_blank"
                                style={{ color: "#fff" }}
                              >
                                <PrintIcon /> {INVOICE}
                              </a>
                            </Button>
                          ) : btnType?.type === "request" ? (
                            <Button
                              variant="contained"
                              size="medium"
                              style={{ marginLeft: "4px", marginRight: "4px" }}
                              color="primary"
                            >
                              <a
                                href={`${BASE_URL}/reservations/getRequestPdf?sid=${user?.session_id}&tk=${user?.accessToken}&reservation_id=${id}&type=request&hotel_id=${hotel?.id}`}
                                target="_blank"
                                style={{ color: "#fff" }}
                              >
                                <PrintIcon /> {REQUEST}
                              </a>
                            </Button>
                          ) : (
                            <Button
                              variant="contained"
                              size="medium"
                              style={{ marginLeft: "4px", marginRight: "4px" }}
                              color="primary"
                              onClick={() => {
                                setBtnType({ status: true, type: "voucher" });
                              }}
                            >
                              <a
                                href={`${BASE_URL}/reservations/getRequestPdf?sid=${user?.session_id}&tk=${user?.accessToken}&reservation_id=${id}&type=voucher&hotel_id=${hotel?.id}`}
                                target="_blank"
                                style={{ color: "#fff" }}
                              >
                                <PrintIcon /> {VOUCHER}
                              </a>
                            </Button>
                          )}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Collapse>
        </Grid>
      )}
      <Grid item xs={12}>
        <Stack direction="row" py={2} justifyContent="space-between">
          <Button
            variant="contained"
            color="primary"
            disabled={activeStep === 0}
            onClick={back}
            sx={{ mr: 1 }}
          >
            {PREVIOUS}
          </Button>
          <Button variant="contained" color="primary" onClick={handleSubmit}>
            {id ? MODIFY : SUBMIT}
          </Button>
        </Stack>
      </Grid>
    </Grid>
  );
};

export default ConfirmationTabContent;
