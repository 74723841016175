import { Box, Grid, Divider, FormControl, TextField, Button, Stack, Tooltip } from "@mui/material";
import { useEffect, useState } from "react";
import { Check, Close } from "@mui/icons-material";
import { AGENCY_RESELLER, RESELLER, VOUCHER } from "./const";
import ResellerAgencyFilter from "./components/ResellerAgencyFilter";
import HotelFilter from "./components/HotelFilter";
import Post from "lib/api/post";
import { SELECT_USER } from "store/slices/profile";
import NewRoomForm from "./components/NewRoomForm";
import Status from "./components/Status";
import { useFormContext } from "../FormContextProvide";
import { NEXT, PREVIOUS } from "../const";
import { isEmpty } from "lodash";
import MDSnackbar from "components/MDSnackbar";
import { useRequirmentContext } from "../RequirmentContextProvider";
import { useParams } from "react-router-dom";

interface VoucherDataType {
  isValid: boolean;
  value: string;
}

type UserType = {
  company: string;
  fullname: string;
  meta_tags: string;
  user_id: number;
  user_type: string;
};
type HotelsType = {
  address: string;
  district: number | string;
  district_name: string;
  fullname: string;
  hotel_code: string;
  hotel_id: number;
  hotel_type: number;
  location: string;
  state: number;
  state_name: string;
  type_code: string;
  type_title: string;
}[];

type Props = {
  next: () => void;
  back: () => void;
  activeStep: number;
};

function HotelTabContent({ next, back, activeStep }: Props) {
  const { formContext, setFormContext } = useFormContext();
  const { requirmentContext } = useRequirmentContext();
  const { id } = useParams();

  const [errors] = useState<string[]>([]);
  const [openErrorMessage, setOpenErrorMessage] = useState<boolean>(false);

  const user = SELECT_USER();

  const [filterAgencyResellerValue, setFilterAgencyResellerValue] = useState(null);
  const [filterResellerValue, setFilterResellerValue] = useState(null);
  const [filerHotelValue, setFilerHotelValue] = useState([]);
  const [voucherList, setVoucherList] = useState({ last: [] });

  const [voucherData, setVoucherData] = useState<VoucherDataType>({
    isValid: false,
    value: "",
  });
  const [voucherDataInput, setVoucherDataInput] = useState("");
  useEffect(() => {
    if (openErrorMessage) {
      setTimeout(() => setOpenErrorMessage(false), 5000);
    }
  }, [openErrorMessage]);
  useEffect(() => {
    setVoucherList({ last: requirmentContext?.vouchers });
  }, [requirmentContext]);

  useEffect(() => {
    setFilterAgencyResellerValue({
      company: formContext.agancy_company,
      user_id: formContext.agancy_id,
      fullname: formContext.agancy_name,
    });
    setFilterResellerValue({
      user_id: formContext.resseller_id,
      fullname: formContext.resseller_name,
    });
    // id
    //   ? formContext?.hotels?.length !== 0 &&
    //     formContext?.hotels?.forEach((hotel: any) => {
    //       setFilerHotelValue((prev) => {
    //         if (!prev.some((item) => item.hotel_id === hotel.id)) {
    //           return [
    //             ...prev.filter((item) => item?.hotel_id !== undefined),
    //             {
    //               hotel_id: hotel?.id,
    //               fullname: hotel?.hotel_name || hotel?.fullname,
    //             },
    //           ];
    //         }
    //         return prev;
    //       });
    //     })
    //   : 
      formContext?.hotels?.length !== 0 &&
        formContext?.hotels?.forEach((hotel: any) => {
          setFilerHotelValue((prev) => {
            if (!prev.some((item) => item.hotel_id === hotel.hotel_id)) {
              return [
                ...prev,
                {
                  hotel_id: hotel?.hotel_id,
                  fullname: hotel?.fullname,
                },
              ];
            }
            return prev;
          });
        });
    setVoucherDataInput(formContext.voucher);
    setVoucherData({ ...voucherData, value: formContext.voucher });
  }, [formContext]);
  useEffect(() => {
    if (!formContext?.agancy_company && !formContext?.agancy_id && !formContext?.agancy_name) {
      setFilterAgencyResellerValue(null);
    }
    if (!formContext?.resseller_id && !formContext?.resseller_name) {
      setFilterResellerValue(null);
    }
    if (!formContext?.hotels && formContext?.hotels?.length !== 0) {
      setFilerHotelValue([]);
    }
    if (!formContext.voucher) {
      setVoucherDataInput("");
    }
  }, [formContext]);

  const handleVoucher = (e: any) => {
    const inputValue = e.target.value;
    setVoucherDataInput(e.target.value);
    const payload = { user_id: user?.uid, session_id: user?.session_id, voucher: inputValue };

    Post.checkVoucher(payload)
      .then((res) => {
        const { data } = res;
        setVoucherData({ value: inputValue, isValid: data?.valid });
      })
      .catch((err) => console.log(err));
  };

  const CheckRequired = () => {
    if (isEmpty(formContext.voucher)) {
      errors.push("voucher is required");
      return false;
    } else if (!formContext.agancy_id) {
      errors.push("agency is required");
      return false;
    } else if (!formContext.resseller_id) {
      errors.push("reseller is required");
      return false;
    } else if (isEmpty(formContext.hotels)) {
      errors.push("At least you have to choose a hotel");
      return false;
    } else if (isEmpty(formContext.rooms)) {
      errors.push("You have not selected a room yet");
      return false;
    } else return true;
  };

  return (
    <Stack style={{ padding: "30px 10px" }} spacing={3}>
      <Grid container spacing={5}>
        <Grid item xs={12} sm={6}>
          <Status setFormContext={setFormContext} formContext={formContext} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Stack direction="row" spacing={1.25} alignItems="center">
            <FormControl required fullWidth>
              <Tooltip
                title={
                  voucherList?.last?.length > 0
                    ? voucherList?.last?.map((voucherName: any) => {
                        return (
                          <Grid item xs={12} sm={12}>
                            {voucherName?.voucher}
                          </Grid>
                        );
                      })
                    : ""
                }
                placement="bottom"
              >
                <TextField
                  required
                  fullWidth
                  label={VOUCHER}
                  variant="outlined"
                  value={voucherDataInput}
                  onChange={handleVoucher}
                  onBlur={() => {
                    voucherData.isValid &&
                      setFormContext({ ...formContext, voucher: voucherData.value });
                  }}
                />
              </Tooltip>
            </FormControl>

            <Box mt={4.5} ml={2} sx={{ display: "flex" }}>
              {voucherData?.isValid && voucherData?.value ? (
                <Check htmlColor="green" fontSize="medium" />
              ) : (
                <Close htmlColor="red" fontSize="medium" />
              )}
            </Box>
          </Stack>
        </Grid>
        <Grid item xs={12} sm={6}>
          <ResellerAgencyFilter
            filterResellerValue={filterAgencyResellerValue}
            setFilterResellerValue={(user: UserType) => {
              setFormContext({
                ...formContext,
                agancy_company: user.company,
                agancy_id: user.user_id,
                agancy_name: user.fullname,
                agency_note: "",
              });
              setFilterAgencyResellerValue(user);
            }}
            title={AGENCY_RESELLER}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <ResellerAgencyFilter
            filterResellerValue={filterResellerValue}
            setFilterResellerValue={(user: UserType) => {
              setFormContext({
                ...formContext,
                resseller_id: user.user_id,
                resseller_name: user.fullname,
              });
              setFilterResellerValue(user);
            }}
            title={RESELLER}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <HotelFilter
            setFilerHotelValue={(items: HotelsType) => {
              const listID: {
                hotel_id: number;
                fullname: string;
                location: string;
                state_name: string;
              }[] = [];
              items.forEach((item) =>
                listID.push({
                  hotel_id: item.hotel_id,
                  fullname: item.fullname,
                  location: item.location,
                  state_name: item.state_name,
                })
              );
              setFormContext({ ...formContext, hotels: listID });
              setFilerHotelValue(items);
            }}
            filerHotelValue={filerHotelValue}
          />
        </Grid>
        <Grid item xs={12}>
          <Divider sx={{ borderWidth: "1px", backgroundColor: "#d2d6da" }} />
        </Grid>
        <Grid item xs={12}>
          <NewRoomForm filerHotelValue={filerHotelValue} />
        </Grid>
        <Grid item xs={12}>
          <Stack direction="row" py={2} justifyContent="space-between">
            <Button
              variant="contained"
              color="primary"
              disabled={activeStep === 0}
              onClick={back}
              sx={{ mr: 1 }}
            >
              {PREVIOUS}
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                const passedFields = CheckRequired();
                if (passedFields) {
                  next?.();
                } else {
                  setOpenErrorMessage(true);
                }
              }}
            >
              {NEXT}
            </Button>
          </Stack>
        </Grid>
      </Grid>
      {openErrorMessage &&
        errors.map((error: string) => (
          <MDSnackbar
            color="error"
            icon="notifications"
            title="error from server"
            content={error}
            dateTime=""
            open={true}
            close={() => setOpenErrorMessage(false)}
          />
        ))}
    </Stack>
  );
}

export default HotelTabContent;
