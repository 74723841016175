import { useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  Select,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  Typography,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import {
  ADD_ROOM_TYPE,
  COUNT,
  HOTEL,
  HOTEL_NAME,
  NEW_ROOM,
  REMOVE,
  ROOM_TYPE,
  SELECT_SUB_TYPE,
  SELECT_TYPE,
} from "../const";
import { styled } from "@mui/material/styles";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import { useFormik } from "formik";
import { v4 as uuidv4 } from "uuid";
import { useFormContext } from "../../FormContextProvide";
import { useRequirmentContext } from "../../RequirmentContextProvider";
import { TextField } from "@material-ui/core";
import { initialPassenger } from "./helper";
import { useParams } from "react-router-dom";

const NewRoomForm = ({ filerHotelValue }: any) => {
  const { formContext, setFormContext } = useFormContext();
  const { requirmentContext } = useRequirmentContext();
  const { id } = useParams();
  const [tableData, setTableData] = useState(formContext?.rooms);
  const [order, setOrder] = useState(1);

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  const { handleSubmit, handleChange, handleBlur, values } = useFormik<any>({
    initialValues: {
      hotel_id: "",
      select_type: "",
      select_subtype: "",
      count: 0,
      hotel: "",
    },
    onSubmit: (values) => {
      const hotel = JSON.parse(values?.hotel);
      const select_type = JSON.parse(values?.select_type);

      const select_subtype = JSON.parse(values?.select_subtype);
      const passengers = [];
      for (let i = 0; i < select_type.capacity * values?.count; i++) {
        passengers.push({ id: uuidv4(), ...initialPassenger });
      }
      const financial: any[] = [];
      const extras: any[] = [];
      const payload = {
        hotel_id: hotel?.hotel_id,
        hotel_title: hotel?.fullname,
        room_type1: select_type?.type_id,
        room_type1_title: select_type?.title,
        room_type1_code: select_type?.type_code,
        room_type1_capacity: select_type.capacity,
        room_type2: select_subtype?.type_id,
        room_type2_title: select_subtype?.title,
        room_type2_code: select_subtype?.type_code,
        count: values?.count,
        pax: 0,
        passengers: passengers,
        financial: financial,
        extras,
        id: uuidv4(),
        order: order,
        state_name: hotel?.state_name,
        location: hotel?.location,
      };

      setTableData([...tableData, payload]);
      setFormContext({ ...formContext, rooms: [...tableData, payload] });
      setOrder((order) => order + 1);
    },
  });

  const handleDeletes = (item: any) => {
    const filteredArray = id
      ? tableData?.filter((obj: any) => obj.room_id !== item.room_id)
      : tableData?.filter((obj: any) => obj.id !== item.id);
    setTableData(filteredArray);
    setFormContext({ ...formContext, rooms: filteredArray });
  };
  useEffect(() => {
    if (formContext?.rooms?.length === 0) {
      setTableData([]);
    } else {
      setTableData(formContext?.rooms);
    }
  }, [formContext?.rooms]);
  return (
    <Grid container spacing={3}>
      <Grid item xs={12} marginY={1}>
        <Typography>{NEW_ROOM}</Typography>
        <Card
          sx={{
            borderRadius: 2,
            p: 2,
            border: "1px solid #f1f1f1",
          }}
        >
          <form onSubmit={handleSubmit}>
            <Grid container spacing={3}>
              <Grid xs={12} sm={6} item>
                <FormControl fullWidth required>
                  <InputLabel id="hotel">{HOTEL}</InputLabel>
                  <Select
                    native
                    open={true}
                    label={HOTEL}
                    fullWidth
                    required
                    name="hotel"
                    id="hotel"
                    value={values.hotel}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  >
                    <option aria-label="None" value="" />
                    {filerHotelValue?.map((item: any) => (
                      <option
                        key={item.hotel_id}
                        value={JSON.stringify({
                          hotel_id: item.hotel_id,
                          fullname: item.fullname,
                          state_name: item.state_name,
                          location: item.location,
                        })}
                      >
                        {item.fullname}
                      </option>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid xs={12} sm={6} item>
                <FormControl fullWidth required>
                  <InputLabel id="select_type">{SELECT_TYPE}</InputLabel>
                  <Select
                    native
                    fullWidth
                    name="select_type"
                    id="select_type"
                    required
                    label={SELECT_TYPE}
                    value={values.select_type}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  >
                    <option aria-label="None" value="" />
                    {requirmentContext?.room_types?.map((item: any, key: number) => (
                      <optgroup label={item.title} key={key}>
                        <option
                          key={item.type_id}
                          value={JSON.stringify({
                            type_id: item.type_id,
                            title: item.title,
                            type_code: item.type_code,
                            capacity: item.capacity,
                          })}
                        >
                          {item.type_code}
                        </option>
                      </optgroup>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid xs={12} sm={6} item>
                <FormControl fullWidth required>
                  <InputLabel id="select_subtype">{SELECT_SUB_TYPE}</InputLabel>
                  <Select
                    native
                    label={SELECT_SUB_TYPE}
                    fullWidth
                    name="select_subtype"
                    id="select_subtype"
                    required
                    value={values.select_subtype}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  >
                    <option aria-label="None" value="" />
                    {requirmentContext?.room_subtypes?.map((item: any, key: number) => (
                      <optgroup label={item.title} key={key}>
                        <option
                          value={JSON.stringify({
                            type_id: item.type_id,
                            title: item.title,
                            type_code: item.type_code,
                          })}
                        >
                          {item.type_code}
                        </option>
                      </optgroup>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid xs={12} sm={6} item>
                <FormControl fullWidth required>
                  <TextField
                    type="number"
                    label={COUNT}
                    variant="outlined"
                    required
                    name="count"
                    value={values.count}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    inputProps={{ min: 1 }}
                  />
                </FormControl>
              </Grid>
              <Grid xs={12} item display="flex" p={1} justifyContent="flex-end">
                <Button variant="contained" color="primary" type="submit" startIcon={<AddIcon />}>
                  {ADD_ROOM_TYPE}
                </Button>
              </Grid>
            </Grid>
          </form>
        </Card>
      </Grid>
      <Grid xs={12} item>
        <Divider sx={{ borderWidth: "1px", backgroundColor: "#d2d6da" }} />
      </Grid>
      <Grid xs={12} item>
        <TableContainer component={Paper} sx={{ mt: 2 }}>
          <Table aria-label="customized table">
            <TableHead sx={{ display: "contents" }}>
              <TableRow>
                <TableCell align="left">{HOTEL_NAME}</TableCell>
                <TableCell align="left">{ROOM_TYPE}</TableCell>
                <TableCell align="left">{COUNT}</TableCell>
                <TableCell align="right">{REMOVE}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {formContext?.rooms?.map((row: any) => (
                <StyledTableRow key={row.id}>
                  <StyledTableCell align="left">{row?.hotel_title}</StyledTableCell>
                  <StyledTableCell align="left">{`${row?.room_type1_title} > ${row?.room_type2_title} `}</StyledTableCell>
                  <StyledTableCell align="left">{row?.count}</StyledTableCell>

                  <StyledTableCell align="right">
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={() => handleDeletes(row)}
                    >
                      <CloseIcon />
                    </Button>
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
};

export default NewRoomForm;
