/**
 =========================================================
 * Material Dashboard 2 PRO React TS - v1.0.0
 =========================================================
 
 * Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)
 
 Coded by www.creative-tim.com
 
  =========================================================
 
 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */
 
 import { useState, useEffect } from "react";
 
 // react-router components
 import { useLocation, Link, useNavigate } from "react-router-dom";
 
 // @material-ui core components
 import AppBar from "@mui/material/AppBar";
 import Toolbar from "@mui/material/Toolbar";
 import IconButton from "@mui/material/IconButton";
 import Menu from "@mui/material/Menu";
 import Icon from "@mui/material/Icon";
 
 // Material Dashboard 2 PRO React TS components
 import MDBox from "components/MDBox";
 import MDInput from "components/MDInput";
 import MDBadge from "components/MDBadge";
 
 // Material Dashboard 2 PRO React TS examples components
 import Breadcrumbs from "examples/Breadcrumbs";
 import NotificationItem from "examples/Items/NotificationItem";
 
 // Custom styles for DashboardNavbar
 import {
   navbar,
   navbarContainer,
   navbarRow,
   navbarIconButton,
   navbarDesktopMenu,
   navbarMobileMenu,
 } from "examples/Navbars/DashboardNavbar/styles";
 
 // Material Dashboard 2 PRO React context
 import {
   useMaterialUIController,
   setTransparentNavbar,
   setMiniSidenav,
   setOpenConfigurator,
 } from "context";
 import { Axios } from "utils/axios";
 import { useDispatch } from "react-redux";
 import { updateUser } from "store/slices/profile";
 import { useFormContext } from "layouts/pages/dashboard/reservation/hotelReservation/FormContextProvide";
import BreadcrumbsReservation from "examples/Breadcrumbs/breadcrumbsReservation";
 
 // Declaring prop types for DashboardNavbar
 interface Props {
   absolute?: boolean;
   light?: boolean;
   isMini?: boolean;
 }
 
 function DashboardNavbarReservation({ absolute, light, isMini }: Props): JSX.Element {
   const [navbarType, setNavbarType] = useState<
     "fixed" | "absolute" | "relative" | "static" | "sticky"
   >();
   const [controller, dispatch] = useMaterialUIController();
   const { miniSidenav, transparentNavbar, fixedNavbar, openConfigurator, darkMode } = controller;
   const [openMenu, setOpenMenu] = useState<any>(false);
   const { formContext, setFormContext } = useFormContext();
   const [openUserMenu, setOpenUserMenu] = useState<any>(false);
   const route = useLocation().pathname.split("/").slice(1);
   const dispatchStore = useDispatch();
   const navigator = useNavigate();
   useEffect(() => {
     // Setting the navbar type
     if (fixedNavbar) {
       setNavbarType("sticky");
     } else {
       setNavbarType("static");
     }
 
     // A function that sets the transparent state of the navbar.
     function handleTransparentNavbar() {
       setTransparentNavbar(dispatch, (fixedNavbar && window.scrollY === 0) || !fixedNavbar);
     }
 
     /** 
      The event listener that's calling the handleTransparentNavbar function when 
      scrolling the window.
     */
     window.addEventListener("scroll", handleTransparentNavbar);
 
     // Call the handleTransparentNavbar function to set the state with the initial value.
     handleTransparentNavbar();
 
     // Remove event listener on cleanup
     return () => window.removeEventListener("scroll", handleTransparentNavbar);
   }, [dispatch, fixedNavbar]);
 
   const handleMiniSidenav = () => setMiniSidenav(dispatch, !miniSidenav);
   const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);
   const handleOpenMenu = (event: any) => setOpenMenu(event.currentTarget);
   const handleOpenUserMenu = (event: any) => setOpenUserMenu(event.currentTarget);
   const handleCloseMenu = () => {
     setOpenMenu(false);
     setOpenUserMenu(false);
   };
 
   // Render the notifications menu
   const renderMenu = () => (
     <Menu
       anchorEl={openMenu}
       anchorReference={null}
       anchorOrigin={{
         vertical: "bottom",
         horizontal: "left",
       }}
       open={Boolean(openMenu)}
       onClose={handleCloseMenu}
       sx={{ mt: 2 }}
     >
       <NotificationItem icon={<Icon>email</Icon>} title="Check new messages" />
       <NotificationItem icon={<Icon>podcasts</Icon>} title="Manage Podcast sessions" />
       <NotificationItem icon={<Icon>shopping_cart</Icon>} title="Payment successfully completed" />
     </Menu>
   );
 
   const logout = () => {
     localStorage.token = "";
     localStorage.user = "";
     Axios.defaults.headers.common.Authorization = "";
     dispatchStore(updateUser());
   };
 
   const renderUserMenu = () => (
     <Menu
       anchorEl={openUserMenu}
       anchorReference={null}
       anchorOrigin={{
         vertical: "bottom",
         horizontal: "left",
       }}
       open={Boolean(openUserMenu)}
       onClose={handleCloseMenu}
       sx={{ mt: 2 }}
     >
       <NotificationItem onClick={logout} icon={<Icon>logout</Icon>} title="logout" />
     </Menu>
   );
 
   // Styles for the navbar icons
   const iconsStyle = ({
     palette: { dark, white, text },
     functions: { rgba },
   }: {
     palette: any;
     functions: any;
   }) => ({
     color: () => {
       let colorValue = light || darkMode ? white.main : dark.main;
 
       if (transparentNavbar && !light) {
         colorValue = darkMode ? rgba(text.main, 0.6) : text.main;
       }
 
       return colorValue;
     },
   });
   return (
     <AppBar
       position={absolute ? "absolute" : navbarType}
       color="inherit"
       sx={(theme) => navbar(theme, { transparentNavbar, absolute, light, darkMode })}
     >
       <Toolbar sx={navbarContainer}>
         <MDBox color="inherit" mb={{ xs: 1, md: 0 }} sx={(theme) => navbarRow(theme, { isMini })}>
           <BreadcrumbsReservation
             icon="home"
             title={route[route.length - 1]}
             voucherId={formContext?.voucher ? formContext?.voucher : ""}
             route={route}
             light={light}
           />
           <IconButton sx={navbarDesktopMenu} onClick={handleMiniSidenav} size="small" disableRipple>
             <Icon fontSize="medium" sx={iconsStyle}>
               {miniSidenav ? "menu_open" : "menu"}
             </Icon>
           </IconButton>
         </MDBox>
         {isMini ? null : (
           <MDBox sx={(theme) => navbarRow(theme, { isMini })}>
             {/* <MDBox pr={1}>
               <MDInput label="Search here" />
             </MDBox> */}
             <MDBox color={light ? "white" : "inherit"}>
               {/* <Link to="/authentication/sign-in/basic">
                 <IconButton sx={navbarIconButton} size="small" disableRipple>
                   <Icon sx={iconsStyle}>account_circle</Icon>
                 </IconButton>
               </Link> */}
               <IconButton
                 size="small"
                 color="inherit"
                 sx={navbarIconButton}
                 onClick={handleOpenUserMenu}
               >
                 <Icon sx={iconsStyle}>account_circle</Icon>
               </IconButton>
               {renderUserMenu()}
               <IconButton
                 size="small"
                 disableRipple
                 color="inherit"
                 sx={navbarMobileMenu}
                 onClick={handleMiniSidenav}
               >
                 <Icon sx={iconsStyle} fontSize="medium">
                   {miniSidenav ? "menu_open" : "menu"}
                 </Icon>
               </IconButton>
               <IconButton
                 size="small"
                 disableRipple
                 color="inherit"
                 sx={navbarIconButton}
                 onClick={handleConfiguratorOpen}
               >
                 <Icon sx={iconsStyle}>settings</Icon>
               </IconButton>
               <IconButton
                 size="small"
                 color="inherit"
                 sx={navbarIconButton}
                 onClick={handleOpenMenu}
               >
                 <MDBadge badgeContent={9} color="error" size="xs" circular>
                   <Icon sx={iconsStyle}>notifications</Icon>
                 </MDBadge>
               </IconButton>
               {renderMenu()}
             </MDBox>
           </MDBox>
         )}
       </Toolbar>
     </AppBar>
   );
 }
 
 // Declaring default props for DashboardNavbar
 DashboardNavbarReservation.defaultProps = {
   absolute: false,
   light: false,
   isMini: false,
 };
 
 export default DashboardNavbarReservation;
 